<template>
    <div id="home">
        <!-- header -->
        <div class="home_bg w100">
            <img src="../assets/imgs/top-home1.jpg" alt="top-home.jpg" class="home_bigbg" />
            <Header></Header>
            <Culture>
                <h3 slot="title1">关于雷博</h3>
                <p slot="title3">ABOUT LEIBO</p>
            </Culture>
        </div>
        <!-- INTRODUCE  -->
        <div id="introduce" class="w1200">
            <Title></Title>
            <div class="introduce_container">
                <div class="introduce_left">
                    <div class="introduce_left_cont">
                        <img src="../assets/imgs/dasha2.jpg" alt="大厦2.jpg" />
                    </div>
                </div>
                <div class="introduce_right">
                    <div class="content introduce_container_right">
                        <p>北京雷博优尼科技有限公司是一家专注于软件开发和实施服务的信息技术公司。</p>
                        <p>
                            公司由北京亿惠达电子科技有限公司工会事业部拆分独立组建，由资深管理咨询专家、企业信息化建设规划专家、软件开发资深工程师以及具有丰富项目实施服务经验的服务顾问组成。自2015年起，团队进入工会服务信息化领域，启动工会信息化服务管理系统的研制开发，并陆续推出数字工会管理软件1.0、2.0、3.0系列产品。
                        </p>
                        <p>
                            2019年与FESCO战略合作，联手深耕线上工会服务领域，共同打造工惠通、工惠优选、工惠广场等系列产品。由单纯的提供软件产品，转向平台运营。产品采用SAAS平台架构，全面支持移动终端，为企业信息化智慧化工会平台建设提供高品质的软件产品和“指尖化”的便捷服务。
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <!-- HISTORY -->
        <div id="history" class="history w1200">
            <Title>
                <h5 slot="zh">发展历程</h5>
                <span slot="en">development history</span>
            </Title>
            <div class="history_course">
                <div class="history_all">
                    <!-- <div class="history_all_top">
                        <p>2015年 - 2019年</p>
                        <p>2020年 - 2021年</p>
                    </div> -->
                    <div class="history_all_bottom">
                        <div>
                            <p>2010年10月：</p>
                            <p>在“流通领域立体化营销及综合服务平台系统”中开发了“工会管理子系统”；</p>
                        </div>
                        <div>
                            <p>2011年1月：</p>
                            <p>广西佳用商贸股份公司上线“流通领域立体化营销及综合服务平台系统”的工会管理子系统；</p>
                        </div>
                        <div>
                            <p>2011年8月：</p>
                            <p>山东全福元商业集团上线“流通领域立体化营销及综合服务平台系统”的工会管理子系统；</p>
                        </div>
                        <div>
                            <p>2012年3月：</p>
                            <p>武汉仟吉食品有限公司“流通领域立体化营销及综合服务平台系统”的工会子系统；</p>
                        </div>
                        <div>
                            <p>2012年7月：</p>
                            <p>晋城凤展购物广场有限公司陆续上线“流通领域立体化营销及综合服务平台系统”的工会子系统；</p>
                        </div>
                        <div>
                            <p>2012年12月：</p>
                            <p>安徽金华联投资股份有限公司上线“流通领域立体化营销及综合服务平台系统”的工会子系统；</p>
                        </div>
                        <div>
                            <p>2013年5月：</p>
                            <p>原“流通领域立体化营销及综合服务平台系统”的工会子模块独立为智慧工会服务管理平台系统；</p>
                        </div>
                        <div>
                            <p>2013年5月：</p>
                            <p>安徽台客隆连锁超市有限责任公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2014年1月起：</p>
                            <p>迭代原有客户的智慧工会服务管理系统；</p>
                        </div>
                        <div>
                            <p>2014年4月：</p>
                            <p>天津众荣科技发展有限公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2015年8月：</p>
                            <p>中百集团武汉生鲜食品加工配送有限公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2016年2月：</p>
                            <p>北京金凤成祥食品有限公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2016年8月：</p>
                            <p>山东日照凌云工贸有限公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2016年10月：</p>
                            <p>山西省原平市东大商贸有限公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2017年10月：</p>
                            <p>安徽台客隆百大合家福上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2018年1月：</p>
                            <p>广西联华超市股份有限公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2018年7月：</p>
                            <p>天津迎宾超市商贸有限公司上线“智慧工会服务管理平台系统”；</p>
                        </div>
                        <div>
                            <p>2019年8月：</p>
                            <p>北京雷博优尼科技有限公司成立于北京</p>
                        </div>
                        <div>
                            <p>2019年9月：</p>
                            <p>与FESCO签署战略合作协议，共同建设智慧工会服务管理平台系统（工惠通智慧工会服务平台）</p>
                        </div>
                        <div>
                            <p>2019年9月：</p>
                            <p>“工惠通”APP正式上线运营</p>
                        </div>
                        <div>
                            <p>2020年6月：</p>
                            <p>智慧工会服务管理平台系统”荣获“2020中国信息技术创新应用”大奖</p>
                        </div>
                        <div>
                            <p>2020年6月：</p>
                            <p>工惠优选服务平台系统上线</p>
                        </div>
                        <div>
                            <p>2020年12月：</p>
                            <p>工惠通平台运营收入突破1个亿；</p>
                        </div>
                        <div>
                            <p>2021年1月：</p>
                            <p>工会激励服务管理平台系统上线；</p>
                        </div>
                        <div>
                            <p>2021年6月：</p>
                            <p>工惠通购物广场上线；</p>
                        </div>
                        <div>
                            <p>2021年8月：</p>
                            <p>慈善募捐平台管理系统上线；</p>
                        </div>
                        <div>
                            <p>2021年11月：</p>
                            <p>金风科技企业后勤服务直通车上线；</p>
                        </div>
                        <div>
                            <p>2021年12月：</p>
                            <p>工惠通（智慧工会服务管理平台系统）荣获“2021最佳用户体验奖”</p>
                        </div>
                        <div>
                            <p>2022年1月：</p>
                            <p>产品征集：福利慰问流程梳理与优化</p>
                        </div>
                        <div>
                            <p>2022年4月：</p>
                            <p>通用程序优化：慰问抽奖、领取积分/慰问券（10种场景）、慰问订单电子签名 </p>
                        </div>
                        <div>
                            <p>2022年11月：</p>
                            <p>安全性提升：用户账号权限规避与实施，浏览器控制台输出屏蔽 </p>
                        </div>
                        <div>
                            <p>2022年12月：</p>
                            <p>工惠优选多平台对接：京东接口右来了接口对接</p>
                        </div>
                        <div>
                            <p>2023年2月：</p>
                            <p>为客户批量下单提升效率进行功能开发</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="history_time w1200">
                <img src="../assets/imgs/times.png" alt="时间轴@2x.png" />
            </div>
        </div>
        <!-- MAIN BUSINESS -->
        <div id="business" class="business">
            <div class="bus_imgs">
                <img src="../assets/imgs/fff000.jpg" alt="黑白背景.png" />
            </div>
            <div class="bus_info">
                <div class="info_l">
                    <div class="info_l_pad">
                        <Title>
                            <h5 slot="zh">主营业务</h5>
                            <span slot="en">MAIN BUSINESS</span>
                        </Title>
                        <p>
                            协同打造智慧工会服务平台，为客户提供合规、专业、丰富、便捷的工会综合解决方案通过线下服务与线上系统形成闭环，全面提升工会体验和管理效能，打造全新数字化工会服务新生态
                        </p>
                        <p>打造新零售业态下，服务于立体营销需求的商业ERP促进企业的服务平台化、流程精细化、决策信息化、信息知识化</p>
                    </div>
                </div>
                <div class="info_r">
                    <div class="info_r_pad">
                        <div class="pad_title">
                            <p>产品优势</p>
                        </div>
                        <div class="pad-list">
                            <div class="pad-list1">
                                <span></span>
                                <h6>专业团队支持</h6>
                                <p>A. 累计服务超过超过200家工会组织10万人次会员</p>
                                <p>B. 提供合法合规，创新多元、专业 便捷的全领域解决方案</p>
                                <p>C. 专属工会管家提供精细化服务</p>
                            </div>
                            <div class="pad-list1">
                                <span></span>
                                <h6>提高工作效率</h6>
                                <p>A. 工惠通打造工会</p>
                                <p>B. 管理者的oa系统</p>
                                <p>C. 事务时间效率提高90%</p>
                            </div>
                            <div class="pad-list1">
                                <span></span>
                                <h6>助力创新实践</h6>
                                <p>A. 能工巧匠、技能练兵</p>
                                <p>B. 知识问答、创新工作室</p>
                                <p>C. 覆盖率*5倍</p>
                            </div>
                            <div class="pad-list1">
                                <span></span>
                                <h6>提高职工素质能力</h6>
                                <p>A. 讲堂直播间、云课堂、云活动</p>
                                <p>B. 朗诵比赛、在线展示&投票</p>
                            </div>
                            <div class="pad-list1">
                                <span></span>
                                <h6>提高数据分析能力</h6>
                                <p>A. 清晰的会员画像</p>
                                <p>B. 可视化解码会员需求</p>
                            </div>
                            <div class="pad-list1">
                                <span></span>
                                <h6>助力智慧工会建设</h6>
                                <p>A. 事先预算</p>
                                <p>B. 事中监控</p>
                                <p>C. 事后分析</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- STRUCTURE -->
        <div id="structure" class="structure w1200">
            <Title>
                <h5 slot="zh">组织架构</h5>
                <span slot="en">ORGANIZATIONAL partner</span>
            </Title>
            <div class="partner_border">
                <img src="../assets/imgs/props.png" alt="props.png" class="partner_imgs" />
            </div>
        </div>
        <!-- partner -->
        <div id="partner" class="partner w1200">
            <div class="partner_info halving">
                <Title>
                    <h5 slot="zh">企业资质</h5>
                    <span slot="en">ENTERPRISE QUALIFICATION</span>
                </Title>
                <div class="qualification">
                    <div>
                        <img src="../assets/imgs/certificate.png" alt="certificate.jpg" class="qualification_imgs_one" />
                    </div>
                    <div>
                        <img src="../assets/imgs/certificate2.jpg" alt="certificate2.jpg" class="qualification_imgs_one" />
                    </div>
                    <div>
                        <img src="../assets/imgs/certificate3.jpg" alt="certificate3.jpg" class="qualification_imgs_two" />
                    </div>
                </div>
            </div>
            <div class="partner_hb halving">
                <Title>
                    <h5 slot="zh">战略合作伙伴</h5>
                    <span slot="en">COOPERATIVE PARTNER</span>
                </Title>
                <div class="partner_company">
                    <!-- flex1 -->
                    <div class="company_info">
                        <!-- <div class="partner_border"></div> -->
                        <div class="partner_imgs">
                            <img src="../assets/imgs/FESCO LOGO(AI).png" alt="FESCO LOGO.png" class="partner_imgs1" />
                        </div>
                        <p>北京外企人力资源服务有限公司</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- partner -->
        <!-- <div class="partner w100">
            <img src="../assets/imgs/img box.png" alt="握手背景.png" class="partner_bd" />
            <div class="partner_info w1200">
                <Title>
                    <h5 slot="zh">战略合作伙伴</h5>
                    <span slot="en">COOPERATIVE PARTNER</span>
                </Title>
                <div class="partner_company w1200">
                    //flex1
                    <div class="company_info">
                        <div class="partner_border"></div>
                        <div class="partner_imgs">
                            <img src="../assets/imgs/FESCO LOGO(AI).png" alt="FESCO LOGO.png" class="partner_imgs1" />
                        </div>
                        <p>北京外企人力资源服务有限公司</p>
                    </div>
                    //flex2
                    <div class="company_info">
                        <div class="partner_border"></div>
                        <div class="partner_imgs">
                            <img src="../assets/imgs/chinaCom.png" alt="中国职工服务集团.png" class="partner_imgs2" />
                        </div>
                        <p>中国职工服务集团</p>
                    </div>
                    //flex3
                    <div class="company_info">
                        <div class="partner_border"></div>
                        <div class="partner_imgs">
                            <img src="../assets/imgs/circle2.png" alt="绿色-logo.png" />
                        </div>
                        <p>中国劳动关系学院</p>
                    </div>
                    //flex4
                    <div class="company_info">
                        <div class="partner_border"></div>
                        <div class="partner_imgs">
                            <img src="../assets/imgs/grrb.png" alt="grrb.png" />
                        </div>
                        <p>工人日报</p>
                    </div>
                    //flex5
                    <div class="company_info">
                        <div class="partner_border"></div>
                        <div class="partner_imgs">
                            <img src="../assets/imgs/logo_new.png" alt="市总LOGO.png" />
                        </div>
                        <p>北京市总工会</p>
                    </div>
                </div>
                <div class="partner_phone">
                    <Title>
                        <h5 slot="zh">联系我们</h5>
                        <span slot="en">CONTACT US</span>
                    </Title>
                    <div class="phone_container">
                        //flex1
                        <div class="phone_info">
                            <div class="phone_border"></div>
                            <p>联系电话</p>
                            <span>010-51722017</span>
                        </div>
                        //flex2
                        <div class="phone_info">
                            <div class="phone_border"></div>
                            <p>公司地址</p>
                            <span>北京市海淀区海淀大街38号银科大厦9层</span>
                        </div>
                        //flex3
                        <div class="phone_info">
                            <div class="phone_border"></div>
                            <p>公司网址</p>
                            <span>www.labour-union.com.cn</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <!-- Footer -->
        <Footer></Footer>
    </div>
</template>

<script>
import Culture from '../components/Culture'
import Header from '../components/Header'
import Footer from '../components/Footer/FootSec.vue'
import Title from '../components/Title'
export default {
  name: 'Home',
  components: {
    Header,
    Culture,
    Title,
    Footer
  }
}
</script>
<style lang="less" scoped>
#home .introduce_container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    overflow: hidden;
    border-bottom: 2px #f2f2f2 solid;
}
#home .home_bigbg {
    height: 34rem;
    width: 100%;
}
.introduce_container .introduce_left {
    width: 42%;
    .introduce_left_cont{
        height: 100%;
        text-align: center;
        img {
        // width: 100%;
        height: 100%;
    }
}
}

.introduce_container .introduce_right {
    width: calc(57% - 50px);
    margin-left: 50px;
}
.introduce_container .introduce_right .content p {
    color: #666666;
    font-family: 微软雅黑;
}
// history
#home .history {
    box-sizing: border-box;
    margin: 1rem auto;
}
.history .history_course {
    padding-left: 5rem;
    height: 62%;
    // margin-top: 30px;
}
.history .history_course .history_all {
    height: 100%;
}
.history .history_all_top {
    display: flex;
    justify-items: center;
}
.history .history_all_top > p {
    width: 50%;
    font-size: 30px;
    line-height: 50px;
    color: #28acff;
}
.history .history_all_bottom {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: calc(100% - 50px);
    font-size: 1.3rem;
    line-height: 3rem;
}
.history .history_all_bottom p {
    display: inline-block;
    font-family: 微软雅黑;
    letter-spacing: 0.15rem;
    color: #666666;
}
.history .history_all_bottom p:nth-child(1) {
    width: 150px;
}
.history .history_time {
    height: calc(40% - 110px);
    display: flex;
    align-items: center;
}
.history .history_time img {
    width: 100%;
}

#home .business {
    width: 1200px;
    height: 750px;
    margin: 0 auto;
    .bus_imgs {
        position: absolute;
        z-index: -10;
        img {
            width: 1200px;
            height: 750px;
            // height: 50rem;
        }
    }
    .bus_info {
        display: flex;
        justify-content: space-between;
        .info_l {
            width: 42%;
            .info_l_pad {
                padding: 50px;
                #title {
                    margin-bottom: 60px;
                    h5 {
                        color: #ffffff;
                    }
                    .border {
                        border-bottom: 6px #ffffff solid;
                    }
                }
                p {
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 56px;
                    margin-bottom: 30px;
                }
            }
        }
        .info_r {
            width: 55%;
            .info_r_pad {
                padding: 64px 130px 0 30px;
                .pad_title {
                    background-color: #b4b4b4;
                    width: 120px;
                    height: 40px;
                    border-radius: 0 0 3px 3px;
                    text-align: center;
                    p {
                        font-size: 20px;
                        color: #ffffff;
                        text-shadow: 0px 3px 1px rgba(0, 51, 120, 0.45);
                        line-height: 40px;
                    }
                }
                .pad-list {
                    padding-top: 50px;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    h6 {
                        font-size: 20px;
                        font-style: italic;
                        font-weight: 600;
                        margin: 0 0 15px 10px;
                    }
                    .pad-list1 {
                        width: 40%;
                        position: relative;
                        margin-bottom: 40px;
                        // background-color: blue;
                    }
                    span {
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        position: absolute;
                        top: 13px;
                        background-color: #28acff;
                    }
                    p {
                        color: #666666;
                        line-height: 24px;
                        font-size: 14px;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

.structure {
    margin-top: 1rem;
}
.structure .partner_border {
    // margin-top: 1rem;
    text-align: center;
}
.structure .partner_imgs {
    width: 95%;
}
.partner_info .qualification {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 1rem 0;
}
.partner_info .qualification img {
    height: 260px;
}
// partner
#home .partner {
    position: relative;
    padding-top: 1rem;
    overflow: hidden;
}
.partner .partner_bd {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
}
.partner .partner_info {
    overflow: hidden;
}
.partner .partner_company {
    padding: 1rem 3rem;
    text-align: left;
    width: 300px;
    // border-bottom: 1px solid #999999;
}
.partner .company_info {
    position: relative;
}
.partner .company_info p {
    font-weight: 400;
    color: #666666;
    line-height: 56px;
}
.partner .company_info .partner_imgs {
    line-height: 100px;
}
.partner .company_info .partner_imgs1 {
    width: 120px;
}
.partner .company_info .partner_imgs2 {
    width: 230px;
}
.partner .company_info img {
    width: 60%;
    margin: 0 auto;
}
//小竖线
.partner .partner_company .partner_border {
    position: absolute;
    right: -32px;
    top: 56px;
    border-right: 1px #b4b4b4 solid;
    opacity: 0.8;
    height: 35px;
}
//小竖线
.partner .partner_phone .phone_border {
    position: absolute;
    right: -110px;
    top: 24px;
    border-right: 1px #b4b4b4 solid;
    opacity: 0.8;
    height: 35px;
}

.partner_phone {
    margin: 30px 0;
}
.partner_phone .phone_container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}
.partner_phone .phone_info {
    position: relative;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
    font-size: 20px;
}
.partner_phone .phone_info span {
    font-weight: 400;
    color: #999999;
    line-height: 24px;
}
</style>
